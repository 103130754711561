//**********************Vendor Branch Details *********************** */

import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";

import { ISearchQueryParams } from "../../common/common.types";
import { getSearchQueryV2 } from "../../common/helpers";
import { IStoreState } from "../../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";
import {
  IVendorAttachment,
  IVendorBank,
  IVendorBranch,
  IVendorContact,
} from "./VendorTabs.types";
import {
  SERVER_GET_VENDOR_ATTACHMENT_ROUTE,
  SERVER_GET_VENDOR_BANK_ROUTE,
  SERVER_GET_VENDOR_BRANCH_ROUTE,
  SERVER_GET_VENDOR_CONTACT_ROUTE,
  SERVER_UPSERT_VENDOR_ATTACHMENT_ROUTE,
  SERVER_UPSERT_VENDOR_BANK_ROUTE,
  SERVER_UPSERT_VENDOR_BRANCH_ROUTE,
  SERVER_UPSERT_VENDOR_CONTACT_ROUTE,
} from "./vendortabs.routes";
import { api } from "../../../api/api";
import { AnyAction } from "redux";

export const FETCH_VENDOR_BRANCH_LIST_PROGRESS =
  "FETCH_VENDOR_BRANCH_LIST_PROGRESS";
export const FETCH_VENDOR_BRANCH_LIST_SUCCESS =
  "FETCH_VENDOR_BRANCH_LIST_SUCCESS";
export const FETCH_VENDOR_BRANCH_LIST_FAILED =
  "FETCH_VENDOR_BRANCH_LIST_FAILED";

export const fetchVendorBranchListProgress = () =>
  action(FETCH_VENDOR_BRANCH_LIST_PROGRESS);
export const fetchVendorBranchListSuccess = (
  list: IVendorBranch[],
  totalRecords: number,
) => action(FETCH_VENDOR_BRANCH_LIST_SUCCESS, { list, totalRecords });
export const fetchVendorBranchListFailed = () =>
  action(FETCH_VENDOR_BRANCH_LIST_FAILED);

export const fetchVendorBranchListAsync =
  (
    queryParams: ISearchQueryParams,
    vendorUUID?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchVendorBranchListProgress());
      let finalUrl = `${SERVER_GET_VENDOR_BRANCH_ROUTE}?${searchQuery}&vendors_uuid=${vendorUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorBranch[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorBranchListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorBranchListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
export const FETCH_VENDOR_BRANCH_PROGRESS = "FETCH_VENDOR_BRANCH_PROGRESS";
export const FETCH_VENDOR_BRANCH_SUCCESS = "FETCH_VENDOR_BRANCH_SUCCESS";
export const FETCH_VENDOR_BRANCH_FAILED = "FETCH_VENDOR_BRANCH_FAILED";

export const fetchVendorBranchProgress = () =>
  action(FETCH_VENDOR_BRANCH_PROGRESS);
export const fetchVendorBranchSuccess = (data: IVendorBranch) =>
  action(FETCH_VENDOR_BRANCH_SUCCESS, { data });
export const fetchVendorBranchFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_BRANCH_FAILED, { errorMessage });

export const fetchCustomerBranchAsync =
  (vendorUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorBranchProgress());
      let finalUrl = `${SERVER_GET_VENDOR_BRANCH_ROUTE}?vendors_branches_uuid=${vendorUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorBranch = res.data.data[0];
      // const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorBranchSuccess(data));
    } catch (err: any) {
      dispatch(fetchVendorBranchFailed(err.response.data.message));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertVendorBranchAsync =
  (
    customerBranch: IVendorBranch,
    onCallback: (isSuccess: boolean, customerBranch?: IVendorBranch) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_name,
        created_by_uuid,
        ...rest
      } = customerBranch;
      const res = await api.post(SERVER_UPSERT_VENDOR_BRANCH_ROUTE, rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vendor Branch saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//**********************Vendor Bank Details *********************** */
export const FETCH_VENDOR_BANK_LIST_PROGRESS =
  "FETCH_VENDOR_BANK_LIST_PROGRESS";
export const FETCH_VENDOR_BANK_LIST_SUCCESS = "FETCH_VENDOR_BANK_LIST_SUCCESS";
export const FETCH_VENDOR_BANK_LIST_FAILED = "FETCH_VENDOR_BANK_LIST_FAILED";

export const fetchVendorBankListProgress = () =>
  action(FETCH_VENDOR_BANK_LIST_PROGRESS);
export const fetchVendorBankListSuccess = (
  list: IVendorBank[],
  totalRecords: number,
) => action(FETCH_VENDOR_BANK_LIST_SUCCESS, { list, totalRecords });
export const fetchVendorBankListFailed = () =>
  action(FETCH_VENDOR_BANK_LIST_FAILED);

export const fetchCustomerBankListAsync =
  (
    queryParams: ISearchQueryParams,
    customerUUID?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchVendorBankListProgress());
      let finalUrl = `${SERVER_GET_VENDOR_BANK_ROUTE}?${searchQuery}&vendors_uuid=${customerUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorBank[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorBankListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorBankListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_VENDOR_BANK_PROGRESS = "FETCH_VENDOR_BANK_PROGRESS";
export const FETCH_VENDOR_BANK_SUCCESS = "FETCH_VENDOR_BANK_SUCCESS";
export const FETCH_VENDOR_BANK_FAILED = "FETCH_VENDOR_BANK_FAILED";

export const fetchVendorBankProgress = () => action(FETCH_VENDOR_BANK_PROGRESS);
export const fetchVendorBankSuccess = (data: IVendorBank) =>
  action(FETCH_VENDOR_BANK_SUCCESS, { data });
export const fetchVendorBankFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_BANK_FAILED, { errorMessage });

export const fetchCustomerBankAsync =
  (vendorUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorBankProgress());
      let finalUrl = `${SERVER_GET_VENDOR_BANK_ROUTE}?vendors_banks_uuid=${vendorUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorBank = res.data.data[0];
      dispatch(fetchVendorBankSuccess(data));
    } catch (err: any) {
      dispatch(fetchVendorBankFailed(err.response.data.message));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertVendorBankAsync =
  (
    customerBank: IVendorBank,
    onCallback: (isSuccess: boolean, customerBank?: IVendorBank) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        created_by_name,
        created_by_uuid,
        insert_ts,
        ...rest
      } = customerBank;
      const res = await api.post(SERVER_UPSERT_VENDOR_BANK_ROUTE, rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vendor Bank Details saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//**********************Vendor contact Details *********************** */

export const FETCH_VENDOR_CONTACT_LIST_PROGRESS =
  "FETCH_VENDOR_CONTACT_LIST_PROGRESS";
export const FETCH_VENDOR_CONTACT_LIST_SUCCESS =
  "FETCH_VENDOR_CONTACT_LIST_SUCCESS";
export const FETCH_VENDOR_CONTACT_LIST_FAILED =
  "FETCH_VENDOR_CONTACT_LIST_FAILED";

export const fetchVendorContactListProgress = () =>
  action(FETCH_VENDOR_CONTACT_LIST_PROGRESS);
export const fetchVendorContactListSuccess = (
  list: IVendorContact[],
  totalRecords: number,
) => action(FETCH_VENDOR_CONTACT_LIST_SUCCESS, { list, totalRecords });
export const fetchVendorContactListFailed = () =>
  action(FETCH_VENDOR_CONTACT_LIST_FAILED);

export const fetchCustomerContactListAsync =
  (
    queryParams: ISearchQueryParams,
    customerUUID?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchVendorContactListProgress());
      let finalUrl = `${SERVER_GET_VENDOR_CONTACT_ROUTE}?${searchQuery}&vendors_uuid=${customerUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorContact[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorContactListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorContactListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_VENDOR_CONTACT_PROGRESS = "FETCH_VENDOR_CONTACT_PROGRESS";
export const FETCH_VENDOR_CONTACT_SUCCESS = "FETCH_VENDOR_CONTACT_SUCCESS";
export const FETCH_VENDOR_CONTACT_FAILED = "FETCH_VENDOR_CONTACT_FAILED";

export const fetchVendorContactProgress = () =>
  action(FETCH_VENDOR_CONTACT_PROGRESS);
export const fetchVendorContactSuccess = (data: IVendorContact) =>
  action(FETCH_VENDOR_CONTACT_SUCCESS, { data });
export const fetchVendorContactFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_CONTACT_FAILED, { errorMessage });

export const fetchCustomerContactAsync =
  (vendorUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorContactProgress());
      let finalUrl = `${SERVER_GET_VENDOR_CONTACT_ROUTE}?vendors_contacts_uuid=${vendorUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorContact = res.data.data[0];

      dispatch(fetchVendorContactSuccess(data));
    } catch (err: any) {
      dispatch(fetchVendorContactFailed(err.response.data.message));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertVendorContactAsync =
  (
    customerBranch: IVendorContact,
    onCallback: (isSuccess: boolean, customerBranch?: IVendorContact) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_name,
        created_by_uuid,
        ...rest
      } = customerBranch;
      const res = await api.post(SERVER_UPSERT_VENDOR_CONTACT_ROUTE, rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vendor Contact saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const FETCH_VENDOR_ATTACHMENT_LIST_PROGRESS =
  "FETCH_VENDOR_ATTACHMENT_LIST_PROGRESS";
export const FETCH_VENDOR_ATTACHMENT_LIST_SUCCESS =
  "FETCH_VENDOR_ATTACHMENT_LIST_SUCCESS";
export const FETCH_VENDOR_ATTACHMENT_LIST_FAILED =
  "FETCH_VENDOR_ATTACHMENT_LIST_FAILED";

export const fetchVendorAttachmentListProgress = () =>
  action(FETCH_VENDOR_ATTACHMENT_LIST_PROGRESS);
export const fetchVendorAttachmentListSuccess = (
  list: IVendorAttachment[],
  totalRecords: number,
) => action(FETCH_VENDOR_ATTACHMENT_LIST_SUCCESS, { list, totalRecords });
export const fetchVendorAttachmentListFailed = () =>
  action(FETCH_VENDOR_ATTACHMENT_LIST_FAILED);

export const fetchCustomerAttachmentListAsync =
  (
    queryParams: ISearchQueryParams,
    customerUUID?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchVendorAttachmentListProgress());
      let finalUrl = `${SERVER_GET_VENDOR_ATTACHMENT_ROUTE}?${searchQuery}&vendors_uuid=${customerUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorAttachment[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchVendorAttachmentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchVendorAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_VENDOR_ATTACHMENT_PROGRESS =
  "FETCH_VENDOR_ATTACHMENT_PROGRESS";
export const FETCH_VENDOR_ATTACHMENT_SUCCESS =
  "FETCH_VENDOR_ATTACHMENT_SUCCESS";
export const FETCH_VENDOR_ATTACHMENT_FAILED = "FETCH_VENDOR_ATTACHMENT_FAILED";

export const fetchVendorAttachmentProgress = () =>
  action(FETCH_VENDOR_ATTACHMENT_PROGRESS);
export const fetchVendorAttachmentSuccess = (data: IVendorAttachment) =>
  action(FETCH_VENDOR_ATTACHMENT_SUCCESS, { data });
export const fetchVendorAttachmentFailed = (errorMessage: string) =>
  action(FETCH_VENDOR_ATTACHMENT_FAILED, { errorMessage });

export const fetchCustomerAttachmentAsync =
  (vendorUUID: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchVendorAttachmentProgress());
      let finalUrl = `${SERVER_GET_VENDOR_ATTACHMENT_ROUTE}?vendors_attachments_uuid=${vendorUUID}`;
      const res = await api.get(finalUrl);
      const data: IVendorAttachment = res.data.data[0];

      dispatch(fetchVendorAttachmentSuccess(data));
    } catch (err: any) {
      dispatch(fetchVendorAttachmentFailed(err.response.data.message));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertVendorAttachmentAsync =
  (
    customerBranch: IVendorAttachment,
    onCallback: (
      isSuccess: boolean,
      customerBranch?: IVendorAttachment,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const { ...rest } = customerBranch;
      const res = await api.post(SERVER_UPSERT_VENDOR_ATTACHMENT_ROUTE, rest);
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Vendor Attachment saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_VENDOR_BRANCH = "CLEAR_VENDOR_BRANCH";
export const CLEAR_VENDOR_BANK = "CLEAR_VENDOR_BANK";
export const CLEAR_VENDOR_CONTACT = "CLEAR_VENDOR_CONTACT";
export const CLEAR_VENDOR_ATTACHMENT = "CLEAR_VENDOR_ATTACHMENT";
export const CLEAR_VENDOR_DETAILS_STATE = "CLEAR_VENDOR_DETAILS_STATE";

export const clearVendorBranch = () => action(CLEAR_VENDOR_BRANCH);
export const clearVendorBank = () => action(CLEAR_VENDOR_BANK);
export const clearVendorContact = () => action(CLEAR_VENDOR_CONTACT);
export const clearVendorAttachment = () => action(CLEAR_VENDOR_ATTACHMENT);
export const clearVendorDetailsState = () => action(CLEAR_VENDOR_DETAILS_STATE);
