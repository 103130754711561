import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { IProductAcrylicFinish } from "./acrylic-finish.types";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_PRODUCT_ACRYLIC_FINISH_LIST_PROGRESS =
  "FETCH_PRODUCT_ACRYLIC_FINISH_LIST_PROGRESS";
export const FETCH_PRODUCT_ACRYLIC_FINISH_LIST_SUCCESS =
  "FETCH_PRODUCT_ACRYLIC_FINISH_LIST_SUCCESS";
export const FETCH_PRODUCT_ACRYLIC_FINISH_LIST_FAILED =
  "FETCH_PRODUCT_ACRYLIC_FINISH_LIST_FAILED";

export const fetchAcrylicFinishListForProductProgress = () =>
  action(FETCH_PRODUCT_ACRYLIC_FINISH_LIST_PROGRESS);
export const fetchAcrylicFinishListForProductSuccess = (
  data: IProductAcrylicFinish[],
  totalRecords: number,
) => action(FETCH_PRODUCT_ACRYLIC_FINISH_LIST_SUCCESS, { data, totalRecords });
export const fetchAcrylicFinishListForProductFailed = () =>
  action(FETCH_PRODUCT_ACRYLIC_FINISH_LIST_FAILED);

export const fetchAcrylicFinishListForProductAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchAcrylicFinishListForProductProgress());
      const res = await api.get(`/it/get-acrylic-finish?${searchQuery}`);

      const data: IProductAcrylicFinish[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchAcrylicFinishListForProductSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchAcrylicFinishListForProductFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleAcrylicFinishFroProductAsync =
  (
    template: IProductAcrylicFinish,
    onCallback: (isSuccess: boolean, data?: IProductAcrylicFinish) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        ...payload
      } = template;

      const res = await api.post("/it/upsert-acrylic-finish", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product Addon saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRODUCT_ACRYLIC_FINISH =
  "CLEAR_SINGLE_PRODUCT_ACRYLIC_FINISH";
export const CLEAR_PRODUCT_ACRYLIC_FINISH_LIST =
  "CLEAR_PRODUCT_ACRYLIC_FINISH_LIST";

export const clearSingleProductAcrylicFinishAsync = () =>
  action(CLEAR_SINGLE_PRODUCT_ACRYLIC_FINISH);
export const clearProductAcrylicFinishListAsync = () =>
  action(CLEAR_PRODUCT_ACRYLIC_FINISH_LIST);
