import { action } from "typesafe-actions";
import { IQueryParams, ISearchQueryParams } from "../../common/common.types";
import { IStoreState } from "../../initialStoreState";
import { AnyAction } from "redux";
import { IProductWoodFinish } from "./wood-finish.types";
import { ThunkAction } from "redux-thunk";
import { getSearchQuery, getSearchQueryV2 } from "../../common/helpers";
import { api } from "../../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../../messages/messagesActions";

export const FETCH_PRODUCT_WOOD_FINISH_LIST_PROGRESS =
  "FETCH_PRODUCT_WOOD_FINISH_LIST_PROGRESS";
export const FETCH_PRODUCT_WOOD_FINISH_LIST_SUCCESS =
  "FETCH_PRODUCT_WOOD_FINISH_LIST_SUCCESS";
export const FETCH_PRODUCT_WOOD_FINISH_LIST_FAILED =
  "FETCH_PRODUCT_WOOD_FINISH_LIST_FAILED";

export const fetchWoodFinishListForProductProgress = () =>
  action(FETCH_PRODUCT_WOOD_FINISH_LIST_PROGRESS);
export const fetchWoodFinishListForProductSuccess = (
  data: IProductWoodFinish[],
  totalRecords: number,
) => action(FETCH_PRODUCT_WOOD_FINISH_LIST_SUCCESS, { data, totalRecords });
export const fetchWoodFinishListForProductFailed = () =>
  action(FETCH_PRODUCT_WOOD_FINISH_LIST_FAILED);

export const fetchWoodFinishListForProductAsync =
  (
    queryParams: ISearchQueryParams,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQueryV2(queryParams);
      dispatch(fetchWoodFinishListForProductProgress());
      const res = await api.get(`/it/get-wood-finish?${searchQuery}`);

      const data: IProductWoodFinish[] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchWoodFinishListForProductSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchWoodFinishListForProductFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertSingleWoodFinishFroProductAsync =
  (
    template: IProductWoodFinish,
    onCallback: (isSuccess: boolean, data?: IProductWoodFinish) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      const {
        create_ts,
        insert_ts,
        created_by_uuid,
        created_by_name,
        modified_by_uuid,
        modified_by_name,
        ...payload
      } = template;

      const res = await api.post("/it/upsert-wood-finish", payload);
      dispatch(
        showMessage({
          type: "success",
          message: "Product Addon saved successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true, res.data.data);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_SINGLE_PRODUCT_WOOD_FINISH =
  "CLEAR_SINGLE_PRODUCT_WOOD_FINISH";
export const CLEAR_PRODUCT_WOOD_FINISH_LIST = "CLEAR_PRODUCT_WOOD_FINISH_LIST";

export const clearSingleProductWoodFinishAsync = () =>
  action(CLEAR_SINGLE_PRODUCT_WOOD_FINISH);
export const clearProductWoodFinishListAsync = () =>
  action(CLEAR_PRODUCT_WOOD_FINISH_LIST);
