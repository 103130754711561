import produce from "immer";

import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultGatePass, defaultGatepassState } from "./gatepass.state";
import {
  CLEAR_GATE_PASS,
  CLEAR_GATE_PASS_STATE,
  FETCH_GATE_PASS_FAILED,
  FETCH_GATE_PASS_LIST_FAILED,
  FETCH_GATE_PASS_LIST_PROGRESS,
  FETCH_GATE_PASS_LIST_SUCCESS,
  FETCH_GATE_PASS_PROGRESS,
  FETCH_GATE_PASS_SUCCESS,
  GatePassActions,
} from ".";
import { CLEAR_VENDOR, defaultVendor, defaultVendorState } from "../vendors";

export const gatePassReducer = (
  state: IStoreState["gatePass"] = defaultGatepassState,
  action: GatePassActions,
) => {
  switch (action.type) {
    case FETCH_GATE_PASS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_GATE_PASS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_GATE_PASS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_GATE_PASS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_gate_pass.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_GATE_PASS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_gate_pass.loading = LoadState.Loaded;
        draftState.single_gate_pass.data = data;
      });
      return newState;
    }
    case FETCH_GATE_PASS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_gate_pass.loading = LoadState.Failed;
        draftState.single_gate_pass.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_GATE_PASS: {
      const newState = produce(state, (draftState) => {
        draftState.single_gate_pass.loading = LoadState.NotLoaded;
        draftState.single_gate_pass.data = defaultGatePass;
        draftState.single_gate_pass.error = null;
      });
      return newState;
    }

    case CLEAR_GATE_PASS_STATE: {
      return defaultGatepassState;
    }

    default: {
      return state;
    }
  }
};
