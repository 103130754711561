import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { APP_PURCHASE_REQUISITION_ROUTE } from "./PurchaseRequisition.constants";

const PurchaseRequisitionListTable = Loadable(
  lazy(() =>
    import("./PurchaseRequisitionListTable").then(
      ({ PurchaseRequisitionListTable }) => ({
        default: PurchaseRequisitionListTable,
      }),
    ),
  ),
);
const ManageSinglePurchaseRequisition = Loadable(
  lazy(() =>
    import("./ManageSinglePurchaseRequisition").then(
      ({ ManageSinglePurchaseRequisition }) => ({
        default: ManageSinglePurchaseRequisition,
      }),
    ),
  ),
);

export const purchaseRequisitionsRoutes = [
  {
    path: APP_PURCHASE_REQUISITION_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_REQUISITION]}>
        <PurchaseRequisitionListTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PURCHASE_REQUISITION_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_REQUISITION]}>
        <ManageSinglePurchaseRequisition />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${APP_PURCHASE_REQUISITION_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_REQUISITION]}>
        <ManageSinglePurchaseRequisition />
      </AuthorizedRoute>
    ),
  },
];
