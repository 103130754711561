import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { PRODUCT_WOOD_FINISH_ROUTE } from "../Product.constants";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";

const WoodFinishTableView = Loadable(
  lazy(() =>
    import("./WoodFinishTableView").then(({ WoodFinishTableView }) => ({
      default: WoodFinishTableView,
    })),
  ),
);

export const productsWoodFinishRoutes = [
  {
    path: PRODUCT_WOOD_FINISH_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <WoodFinishTableView />
      </AuthorizedRoute>
    ),
  },
];
