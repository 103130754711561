import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  PART_ITEMS_ROUTE,
  UPLOAD_PART_ITEMS_ROUTE,
} from "./PartItem.constants";

const PartItemList = Loadable(
  lazy(() =>
    import("./PartItemList").then(({ PartItemList }) => ({
      default: PartItemList,
    })),
  ),
);

const UploadPartItemsFromExcel = Loadable(
  lazy(() =>
    import("./UploadPartItemsFromExcel/UploadPartItemsFromExcel").then(
      ({ UploadPartItemsFromExcel }) => ({
        default: UploadPartItemsFromExcel,
      }),
    ),
  ),
);

export const partItemsRoutes = [
  {
    path: PART_ITEMS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PART_ITEM]}>
        <PartItemList />
      </AuthorizedRoute>
    ),
  },
  {
    path: UPLOAD_PART_ITEMS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PART_ITEM]}>
        <UploadPartItemsFromExcel />
      </AuthorizedRoute>
    ),
  },
];
