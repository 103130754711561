import { LoadState } from "../../../constants/enums";
import {
  IProductMetalFinish,
  IProductMetalFinishState,
} from "./metal-finish.types";

export const defaultProductMetalFinish: IProductMetalFinish = {
  metal_finish_uuid: null,
  metal_finish_name: "",
  description: null,
  status: "ACTIVE",
};

export const defaultProductMetalFinishState: IProductMetalFinishState = {
  multiple_metal_finish_records: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_metal_finish_state: {
    data: defaultProductMetalFinish,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
