import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import {
  defaultVendorAttachment,
  defaultVendorBank,
  defaultVendorBranch,
  defaultVendorContact,
  defaultVendorDetailsState,
} from "./VendorTabs.state";
import {
  CLEAR_VENDOR_ATTACHMENT,
  CLEAR_VENDOR_BANK,
  CLEAR_VENDOR_BRANCH,
  CLEAR_VENDOR_CONTACT,
  CLEAR_VENDOR_DETAILS_STATE,
  FETCH_VENDOR_ATTACHMENT_FAILED,
  FETCH_VENDOR_ATTACHMENT_LIST_FAILED,
  FETCH_VENDOR_ATTACHMENT_LIST_PROGRESS,
  FETCH_VENDOR_ATTACHMENT_LIST_SUCCESS,
  FETCH_VENDOR_ATTACHMENT_PROGRESS,
  FETCH_VENDOR_ATTACHMENT_SUCCESS,
  FETCH_VENDOR_BANK_FAILED,
  FETCH_VENDOR_BANK_LIST_FAILED,
  FETCH_VENDOR_BANK_LIST_PROGRESS,
  FETCH_VENDOR_BANK_LIST_SUCCESS,
  FETCH_VENDOR_BANK_PROGRESS,
  FETCH_VENDOR_BANK_SUCCESS,
  FETCH_VENDOR_BRANCH_FAILED,
  FETCH_VENDOR_BRANCH_LIST_FAILED,
  FETCH_VENDOR_BRANCH_LIST_PROGRESS,
  FETCH_VENDOR_BRANCH_LIST_SUCCESS,
  FETCH_VENDOR_BRANCH_PROGRESS,
  FETCH_VENDOR_BRANCH_SUCCESS,
  FETCH_VENDOR_CONTACT_FAILED,
  FETCH_VENDOR_CONTACT_LIST_FAILED,
  FETCH_VENDOR_CONTACT_LIST_PROGRESS,
  FETCH_VENDOR_CONTACT_LIST_SUCCESS,
  FETCH_VENDOR_CONTACT_PROGRESS,
  FETCH_VENDOR_CONTACT_SUCCESS,
  VendorsDetailsActions,
} from ".";
import { LoadState } from "../../../constants/enums";
import { defaultVendor, defaultVendorState } from "../vendors.state";

export const vendorsDetailsReducer = (
  state: IStoreState["vendorDetails"] = defaultVendorDetailsState,
  action: VendorsDetailsActions,
) => {
  switch (action.type) {
    case FETCH_VENDOR_BRANCH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.InProgress;
        draftState.branchList.list = [];
        draftState.branchList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_VENDOR_BRANCH_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.Loaded;
        draftState.branchList.list = list;
        draftState.branchList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_BRANCH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_VENDOR_BRANCH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleBranchData.loading = LoadState.InProgress;
        draftState.singleBranchData.data = defaultVendorBranch;
      });
      return newState;
    }
    case FETCH_VENDOR_BRANCH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleBranchData.loading = LoadState.Loaded;
        draftState.singleBranchData.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_BRANCH_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleBranchData.loading = LoadState.Failed;
        draftState.singleBranchData.error = errorMessage;
      });
      return newState;
    }
    //bank redux
    case FETCH_VENDOR_BANK_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.bankList.loading = LoadState.InProgress;
        draftState.bankList.list = [];
        draftState.bankList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_VENDOR_BANK_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.bankList.loading = LoadState.Loaded;
        draftState.bankList.list = list;
        draftState.bankList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_BANK_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.bankList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_VENDOR_BANK_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleBankData.loading = LoadState.InProgress;
        draftState.singleBankData.data = defaultVendorBank;
      });
      return newState;
    }
    case FETCH_VENDOR_BANK_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleBankData.loading = LoadState.Loaded;
        draftState.singleBankData.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_BANK_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleBankData.loading = LoadState.Failed;
        draftState.singleBankData.error = errorMessage;
      });
      return newState;
    }
    //contact redux

    case FETCH_VENDOR_CONTACT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.contactList.loading = LoadState.InProgress;
        draftState.contactList.list = [];
        draftState.contactList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_VENDOR_CONTACT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.contactList.loading = LoadState.Loaded;
        draftState.contactList.list = list;
        draftState.contactList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_CONTACT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_VENDOR_CONTACT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleContactData.loading = LoadState.InProgress;
        draftState.singleBranchData.data = defaultVendorBranch;
      });
      return newState;
    }
    case FETCH_VENDOR_CONTACT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleContactData.loading = LoadState.Loaded;
        draftState.singleContactData.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_CONTACT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleContactData.loading = LoadState.Failed;
        draftState.singleContactData.error = errorMessage;
      });
      return newState;
    }

    //atachments

    case FETCH_VENDOR_ATTACHMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.attachmentList.loading = LoadState.InProgress;
        draftState.attachmentList.list = [];
        draftState.attachmentList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_VENDOR_ATTACHMENT_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.attachmentList.loading = LoadState.Loaded;
        draftState.attachmentList.list = list;
        draftState.attachmentList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_VENDOR_ATTACHMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.attachmentList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_VENDOR_ATTACHMENT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.singleAttachmentData.loading = LoadState.InProgress;
        draftState.singleAttachmentData.data = defaultVendorAttachment;
      });
      return newState;
    }
    case FETCH_VENDOR_ATTACHMENT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleAttachmentData.loading = LoadState.Loaded;
        draftState.singleAttachmentData.data = data;
      });
      return newState;
    }
    case FETCH_VENDOR_ATTACHMENT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.singleAttachmentData.loading = LoadState.Failed;
        draftState.singleAttachmentData.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_VENDOR_BRANCH: {
      const newState = produce(state, (draftState) => {
        draftState.singleBranchData.loading = LoadState.NotLoaded;
        draftState.singleBranchData.data = defaultVendorBranch;
        draftState.singleBranchData.error = null;
      });
      return newState;
    }
    case CLEAR_VENDOR_BANK: {
      const newState = produce(state, (draftState) => {
        draftState.singleBankData.loading = LoadState.NotLoaded;
        draftState.singleBankData.data = defaultVendorBank;
        draftState.singleBankData.error = null;
      });
      return newState;
    }
    case CLEAR_VENDOR_CONTACT: {
      const newState = produce(state, (draftState) => {
        draftState.singleContactData.loading = LoadState.NotLoaded;
        draftState.singleContactData.data = defaultVendorContact;
        draftState.singleContactData.error = null;
      });
      return newState;
    }
    case CLEAR_VENDOR_ATTACHMENT: {
      const newState = produce(state, (draftState) => {
        draftState.singleAttachmentData.loading = LoadState.NotLoaded;
        draftState.singleAttachmentData.data = defaultVendorAttachment;
        draftState.singleAttachmentData.error = null;
      });
      return newState;
    }

    case CLEAR_VENDOR_DETAILS_STATE: {
      return defaultVendorDetailsState;
    }

    default: {
      return state;
    }
  }
};
