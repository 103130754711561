import { LoadState } from "../../../constants/enums";
import {
  IProductWoodFinish,
  IProductWoodFinishState,
} from "./wood-finish.types";

export const defaultProductWoodFinish: IProductWoodFinish = {
  wood_finish_uuid: null,
  wood_finish_name: "",
  description: null,
  status: "ACTIVE",
};

export const defaultProductWoodFinishState: IProductWoodFinishState = {
  multiple_wood_finish_records: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_wood_finish_state: {
    data: defaultProductWoodFinish,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
