import React from "react";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { ITextFieldWithLabelProps } from "./TextFieldWithLabel.types";

export const TextFieldWithLabel: React.FC<ITextFieldWithLabelProps> =
  React.memo((props) => {
    const [initalText, setInitalText] = React.useState<string | unknown>(
      undefined,
    );

    React.useEffect(() => {
      setInitalText(props.value);
    }, [props.value]);

    const handleOnblur = (event: React.FocusEvent<HTMLInputElement>) => {
      console.log("handleOnblur ====> ", event.target);
      if (props.onChange) props.onChange(event);
    };

    return (
      <>
        <CustomFormLabel>{props.heading}</CustomFormLabel>
        <CustomTextField
          {...props}
          value={initalText}
          onChange={(evt) => setInitalText(evt.target.value)}
          onBlur={handleOnblur}
          variant="outlined"
          size="small"
          error={props.error ? true : false}
          helperText={props.error ? props.helperText : ""}
        />
      </>
    );
  });

export const NumberFieldWithLabel: React.FC<ITextFieldWithLabelProps> =
  React.memo((props) => {
    return (
      <>
        <CustomFormLabel>{props.heading}</CustomFormLabel>
        <CustomTextField
          {...props}
          variant="outlined"
          size="small"
          placeholder={props.placeholder || "0"}
          value={props.value?.toString() === "0" ? "" : props.value?.toString()}
          onChange={(evt) => {
            if (!props.onChange) return;
            if (
              props.value?.toString().includes(".") &&
              evt.target.value === "."
            )
              return;
            let newEvt = { ...evt };
            const onlyNums = newEvt.target.value
              .replace(/[^0-9.]/g, "")
              .replace(/(\..*)\./g, "$1");
            newEvt.target.value = onlyNums;
            props.onChange(newEvt);
          }}
          error={props.error ? true : false}
          helperText={props.error ? props.helperText : ""}
        />
      </>
    );
  });
