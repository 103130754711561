export const UPLOAD_PART_ITEMS_ROUTE = "/upload-part-items";
export const PART_ITEMS_ROUTE = "/part-items";

export const part_code_prefix_data = [
  "60-I1-ZZ",
  "50-I1-ZZ",
  "20-I1-ZZ",
  "61-i1-ZZ",
  "71-i1-ZZ",
];
