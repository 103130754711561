import { LoadState } from "../../constants/enums";
import { IDrawingItem, IDrawingItemState } from "./drawingItem.types";

export const defaultDrawingItem: IDrawingItem = {
  combined_drawing_no: "",
  create_ts: "",
  created_by_uuid: "",
  drawing_item_uuid: "",
  drawing_link: "",
  drawing_name: "",
  drawing_no: "",
  insert_ts: "",
  revision_no: 0,
  status: "ACTIVE",
};

export const defaultDrawingItemState: IDrawingItemState = {
  list: {
    loading: LoadState.NotLoaded,
    data: [],
    totalRecords: 0,
    error: null,
  },
  drawingItem: {
    loading: LoadState.NotLoaded,
    data: defaultDrawingItem,
    error: null,
  },
};
