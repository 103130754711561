export const SERVER_GET_VENDOR_BRANCH_ROUTE = "/purchase/get-vendors-branches";
export const SERVER_UPSERT_VENDOR_BRANCH_ROUTE =
  "/purchase/upsert-vendors-branches";
export const SERVER_GET_VENDOR_BANK_ROUTE = "/purchase/get-vendors-bankdetails";
export const SERVER_UPSERT_VENDOR_BANK_ROUTE =
  "/purchase/upsert-vendors-bankdetails";
export const SERVER_GET_VENDOR_CONTACT_ROUTE = "/purchase/get-vendors-contacts";
export const SERVER_UPSERT_VENDOR_CONTACT_ROUTE =
  "/purchase/upsert-vendors-contacts";
export const SERVER_GET_VENDOR_ATTACHMENT_ROUTE =
  "/purchase/get-vendors-attachments";
export const SERVER_UPSERT_VENDOR_ATTACHMENT_ROUTE =
  "/purchase/upsert-vendors-attachments";
