import { lazy } from "react";
import Loadable from "../../../layout/fullLayout/loadable/loadable";
import { PRODUCT_METAL_FINISH_ROUTE } from "../Product.constants";
import { MODULE_IDS } from "../../../constants/enums";
import { AuthorizedRoute } from "../../../router/AuthorizedRoute";

const MetalFinishTableView = Loadable(
  lazy(() =>
    import("./MetalFinishTableView").then(({ MetalFinishTableView }) => ({
      default: MetalFinishTableView,
    })),
  ),
);

export const productsMetalFinishRoutes = [
  {
    path: PRODUCT_METAL_FINISH_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <MetalFinishTableView />
      </AuthorizedRoute>
    ),
  },
];
