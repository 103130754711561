import produce from "immer";
import { WarehouseActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import { defaultGrnState } from "./defaultState";
import {
  CLEAR_GRN_STATE,
  FETCH_GRN_FAILED,
  FETCH_GRN_LIST_FAILED,
  FETCH_GRN_LIST_PROGRESS,
  FETCH_GRN_LIST_SUCCESS,
  FETCH_GRN_PROGRESS,
  FETCH_GRN_SUCCESS,
} from "./warehouseGrnActions";

export const warehouseGrnReducer = (
  state: IStoreState["warehouseGrn"] = defaultGrnState,
  action: WarehouseActions,
) => {
  switch (action.type) {
    case FETCH_GRN_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.grnList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_GRN_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.grnList.loading = LoadState.Loaded;
        draftState.grnList.list = data;
        draftState.grnList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_GRN_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.grnList.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_GRN_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.grn.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_GRN_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.grn.loading = LoadState.Loaded;
        draftState.grn.data = data;
      });
      return newState;
    }
    case FETCH_GRN_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.grn.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_GRN_STATE: {
      return defaultGrnState;
    }
    default: {
      return state;
    }
  }
};
