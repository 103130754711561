import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultProductsState } from "./products.state";
import {
  CLEAR_PRODUCT,
  CLEAR_PRODUCT_STATE,
  FETCH_PRODUCTS_LIST_FAILED,
  FETCH_PRODUCTS_LIST_PROGRESS,
  FETCH_PRODUCTS_LIST_SUCCESS,
  FETCH_PRODUCT_FAILED,
  FETCH_PRODUCT_PROGRESS,
  FETCH_PRODUCT_SUCCESS,
} from "./product.actions";
import {
  CLEAR_PRODUCT_TEMPLATE_LIST,
  CLEAR_SINGLE_PRODUCT_TEMPLATE,
  FETCH_PRODUCT_TEMPLATE_LIST_FAILED,
  FETCH_PRODUCT_TEMPLATE_LIST_PROGRESS,
  FETCH_SINGLE_PRODUCT_TEMPLATE_FAILED,
  FETCH_PRODUCT_TEMPLATE_LIST_SUCCESS,
  FETCH_SINGLE_PRODUCT_TEMPLATE_PROGRESS,
  FETCH_SINGLE_PRODUCT_TEMPLATE_SUCCESS,
} from "./product-templates/product-templates.actions";
import { ProductActionsType } from ".";
import {
  CLEAR_PRODUCT_ADD_ON_LIST,
  CLEAR_SINGLE_PRODUCT_ADD_ON,
  FETCH_PRODUCT_ADD_ON_LIST_FAILED,
  FETCH_PRODUCT_ADD_ON_LIST_PROGRESS,
  FETCH_PRODUCT_ADD_ON_LIST_SUCCESS,
  FETCH_SINGLE_PRODUCT_ADD_ON_FAILED,
  FETCH_SINGLE_PRODUCT_ADD_ON_PROGRESS,
  FETCH_SINGLE_PRODUCT_ADD_ON_SUCCESS,
} from "./product-addon";
import {
  CLEAR_PRODUCT_METAL_FINISH_LIST,
  CLEAR_SINGLE_PRODUCT_METAL_FINISH,
  FETCH_PRODUCT_METAL_FINISH_LIST_FAILED,
  FETCH_PRODUCT_METAL_FINISH_LIST_PROGRESS,
  FETCH_PRODUCT_METAL_FINISH_LIST_SUCCESS,
} from "./metal-finish/metal-finish.actions";
import {
  CLEAR_PRODUCT_WOOD_FINISH_LIST,
  CLEAR_SINGLE_PRODUCT_WOOD_FINISH,
  FETCH_PRODUCT_WOOD_FINISH_LIST_FAILED,
  FETCH_PRODUCT_WOOD_FINISH_LIST_PROGRESS,
  FETCH_PRODUCT_WOOD_FINISH_LIST_SUCCESS,
} from "./wood-finish";
import {
  CLEAR_PRODUCT_ACRYLIC_FINISH_LIST,
  CLEAR_SINGLE_PRODUCT_ACRYLIC_FINISH,
  FETCH_PRODUCT_ACRYLIC_FINISH_LIST_FAILED,
  FETCH_PRODUCT_ACRYLIC_FINISH_LIST_PROGRESS,
  FETCH_PRODUCT_ACRYLIC_FINISH_LIST_SUCCESS,
} from "./acrylic-finish";

export const productsReducer = (
  state: IStoreState["products"] = defaultProductsState,
  action: ProductActionsType,
) => {
  switch (action.type) {
    // ###################################################################################
    // ################################# Product Reducer #################################
    // ###################################################################################

    case FETCH_PRODUCTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_PRODUCT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.Loaded;
        draftState.product = data;
      });
      return newState;
    }
    case FETCH_PRODUCT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }

    // ###################################################################################
    // ############################ Product Template Reducer #############################
    // ###################################################################################

    case FETCH_PRODUCT_TEMPLATE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.templates.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_TEMPLATE_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.templates.loading = LoadState.Loaded;
        draftState.templates.data = data;
        draftState.templates.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_TEMPLATE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.templates.loading = LoadState.Failed;
        draftState.templates.data = [];
        draftState.templates.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_PRODUCT_TEMPLATE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_template.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_PRODUCT_TEMPLATE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_template.loading = LoadState.Loaded;
        draftState.single_template.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_PRODUCT_TEMPLATE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_template.loading = LoadState.Failed;
        draftState.single_template.error = errorMessage;
      });
      return newState;
    }

    // ###################################################################################
    // ############################# Product Add On Reducer ##############################
    // ###################################################################################

    case FETCH_PRODUCT_ADD_ON_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.product_add_on_list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_ADD_ON_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.product_add_on_list.loading = LoadState.Loaded;
        draftState.product_add_on_list.data = data;
        draftState.product_add_on_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_ADD_ON_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.product_add_on_list.loading = LoadState.Failed;
        draftState.product_add_on_list.data = [];
        draftState.product_add_on_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_PRODUCT_ADD_ON_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_product_add_on.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_PRODUCT_ADD_ON_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_product_add_on.loading = LoadState.Loaded;
        draftState.single_product_add_on.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_PRODUCT_ADD_ON_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_product_add_on.loading = LoadState.Failed;
        draftState.single_product_add_on.error = errorMessage;
      });
      return newState;
    }

    // ###################################################################################
    // ############################# Product Metal Finish Reducer ##############################
    // ###################################################################################

    case FETCH_PRODUCT_METAL_FINISH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_metal_finish_records.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_METAL_FINISH_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_metal_finish_records.loading = LoadState.Loaded;
        draftState.multiple_metal_finish_records.data = data;
        draftState.multiple_metal_finish_records.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_METAL_FINISH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_metal_finish_records.loading = LoadState.Failed;
        draftState.multiple_metal_finish_records.data = [];
        draftState.multiple_metal_finish_records.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_PRODUCT_METAL_FINISH_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_metal_finish_records.loading = LoadState.NotLoaded;
        draftState.multiple_metal_finish_records.data =
          defaultProductsState["multiple_metal_finish_records"].data;
        draftState.multiple_metal_finish_records.totalRecords = 0;
        draftState.multiple_metal_finish_records.error = null;
      });
      return newState;
    }

    case CLEAR_SINGLE_PRODUCT_METAL_FINISH: {
      const newState = produce(state, (draftState) => {
        draftState.single_metal_finish_state.loading = LoadState.NotLoaded;
        draftState.single_metal_finish_state.data =
          defaultProductsState["single_metal_finish_state"].data;
        draftState.single_metal_finish_state.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################# Product Wood Finish Reducer ##############################
    // ###################################################################################

    case FETCH_PRODUCT_WOOD_FINISH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_wood_finish_records.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_WOOD_FINISH_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_wood_finish_records.loading = LoadState.Loaded;
        draftState.multiple_wood_finish_records.data = data;
        draftState.multiple_wood_finish_records.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_WOOD_FINISH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_wood_finish_records.loading = LoadState.Failed;
        draftState.multiple_wood_finish_records.data = [];
        draftState.multiple_wood_finish_records.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_PRODUCT_WOOD_FINISH_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_wood_finish_records.loading = LoadState.NotLoaded;
        draftState.multiple_wood_finish_records.data =
          defaultProductsState["multiple_wood_finish_records"].data;
        draftState.multiple_wood_finish_records.totalRecords = 0;
        draftState.multiple_wood_finish_records.error = null;
      });
      return newState;
    }

    case CLEAR_SINGLE_PRODUCT_WOOD_FINISH: {
      const newState = produce(state, (draftState) => {
        draftState.single_wood_finish_state.loading = LoadState.NotLoaded;
        draftState.single_wood_finish_state.data =
          defaultProductsState["single_wood_finish_state"].data;
        draftState.single_wood_finish_state.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ############################# Product Acrylic Finish Reducer ##############################
    // ###################################################################################

    case FETCH_PRODUCT_ACRYLIC_FINISH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_acrylic_finish_records.loading =
          LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_PRODUCT_ACRYLIC_FINISH_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.multiple_acrylic_finish_records.loading = LoadState.Loaded;
        draftState.multiple_acrylic_finish_records.data = data;
        draftState.multiple_acrylic_finish_records.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PRODUCT_ACRYLIC_FINISH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_acrylic_finish_records.loading = LoadState.Failed;
        draftState.multiple_acrylic_finish_records.data = [];
        draftState.multiple_acrylic_finish_records.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_PRODUCT_ACRYLIC_FINISH_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.multiple_acrylic_finish_records.loading =
          LoadState.NotLoaded;
        draftState.multiple_acrylic_finish_records.data =
          defaultProductsState["multiple_acrylic_finish_records"].data;
        draftState.multiple_acrylic_finish_records.totalRecords = 0;
        draftState.multiple_acrylic_finish_records.error = null;
      });
      return newState;
    }

    case CLEAR_SINGLE_PRODUCT_ACRYLIC_FINISH: {
      const newState = produce(state, (draftState) => {
        draftState.single_acrylic_finish_state.loading = LoadState.NotLoaded;
        draftState.single_acrylic_finish_state.data =
          defaultProductsState["single_acrylic_finish_state"].data;
        draftState.single_acrylic_finish_state.error = null;
      });
      return newState;
    }

    // ###################################################################################
    // ################################ Clear all States #################################
    // ###################################################################################

    case CLEAR_SINGLE_PRODUCT_TEMPLATE: {
      const newState = produce(state, (draftState) => {
        draftState.single_template.loading = LoadState.NotLoaded;
        draftState.single_template.data =
          defaultProductsState["single_template"].data;
        draftState.single_template.error = null;
      });
      return newState;
    }

    case CLEAR_PRODUCT_TEMPLATE_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.templates.loading = LoadState.NotLoaded;
        draftState.templates.data = defaultProductsState["templates"].data;
        draftState.templates.totalRecords = 0;
        draftState.templates.error = null;
      });
      return newState;
    }

    case CLEAR_SINGLE_PRODUCT_ADD_ON: {
      const newState = produce(state, (draftState) => {
        draftState.single_product_add_on.loading = LoadState.NotLoaded;
        draftState.single_product_add_on.data =
          defaultProductsState["single_product_add_on"].data;
        draftState.single_product_add_on.error = null;
      });
      return newState;
    }

    case CLEAR_PRODUCT_ADD_ON_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.product_add_on_list.loading = LoadState.NotLoaded;
        draftState.product_add_on_list.data =
          defaultProductsState["product_add_on_list"].data;
        draftState.product_add_on_list.totalRecords = 0;
        draftState.product_add_on_list.error = null;
      });
      return newState;
    }

    case CLEAR_PRODUCT: {
      const newState = produce(state, (draftState) => {
        draftState.productLoading = LoadState.NotLoaded;
        draftState.product = defaultProductsState["product"];
      });
      return newState;
    }
    case CLEAR_PRODUCT_STATE: {
      return defaultProductsState;
    }
    default: {
      return state;
    }
  }
};
