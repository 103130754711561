import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { MODULE_IDS } from "../../constants/enums";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  PRODUCTS_ROUTE,
  PRODUCT_TEMPLATES_ROUTE,
  UPLOAD_PRODUCTS_ROUTE,
} from "./Product.constants";
import { productsMetalFinishRoutes } from "./MetalFinish/MetalFinish.router";
import { productsWoodFinishRoutes } from "./WoodFinish/WoodFinish.router";
import { productsAcrylicFinishRoutes } from "./AcrylicFinish/AcrylicFinish.router";

const ProductsTable = Loadable(
  lazy(() =>
    import("./Products").then(({ Products }) => ({
      default: Products,
    })),
  ),
);
const ManageSingleProduct = Loadable(
  lazy(() =>
    import("./ManageProduct").then(({ ManageProduct }) => ({
      default: ManageProduct,
    })),
  ),
);

const ProductTemplatesTable = Loadable(
  lazy(() =>
    import("./ProductTemplates/ProductTemplatesTable").then(
      ({ ProductTemplatesTable }) => ({
        default: ProductTemplatesTable,
      }),
    ),
  ),
);
const ManageProductTemplate = Loadable(
  lazy(() =>
    import("./ProductTemplates/ManageProductTemplate").then(
      ({ ManageProductTemplate }) => ({
        default: ManageProductTemplate,
      }),
    ),
  ),
);

const UploadProductsFromExcel = Loadable(
  lazy(() =>
    import("./UploadProductsFromExcel/UploadProductsFromExcel").then(
      ({ UploadProductsFromExcel }) => ({
        default: UploadProductsFromExcel,
      }),
    ),
  ),
);

export const productsRoutes = [
  {
    path: PRODUCTS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ProductsTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCTS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleProduct />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCTS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageSingleProduct />
      </AuthorizedRoute>
    ),
  },

  {
    path: PRODUCT_TEMPLATES_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ProductTemplatesTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCT_TEMPLATES_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageProductTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PRODUCT_TEMPLATES_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <ManageProductTemplate />
      </AuthorizedRoute>
    ),
  },
  {
    path: UPLOAD_PRODUCTS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PRODUCTS]}>
        <UploadProductsFromExcel />
      </AuthorizedRoute>
    ),
  },
]
  .concat(productsMetalFinishRoutes)
  .concat(productsWoodFinishRoutes)
  .concat(productsAcrylicFinishRoutes);
