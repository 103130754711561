import { LoadState } from "../../../constants/enums";
import {
  IProductAcrylicFinish,
  IProductAcrylicFinishState,
} from "./acrylic-finish.types";

export const defaultProductAcrylicFinish: IProductAcrylicFinish = {
  acrylic_finish_uuid: null,
  acrylic_finish_name: "",
  description: null,
  status: "ACTIVE",
};

export const defaultProductAcrylicFinishState: IProductAcrylicFinishState = {
  multiple_acrylic_finish_records: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  single_acrylic_finish_state: {
    data: defaultProductAcrylicFinish,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
